import React, { FC } from "react";
import { graphql } from "gatsby";

// Prevent fontawesome from dynamically adding its css since we do it manually in gatsby-browser.js
// See https://github.com/FortAwesome/react-fontawesome/issues/134#issuecomment-471940596
import { config } from "@fortawesome/fontawesome-svg-core";
config.autoAddCss = false;

import "../styles/style.scss";

import Layout from "../layout/layout";
import { MetaData } from "../layout/metadata";
import { isAuthenticated, login } from "../utils/auth";
import { ArticleList } from "../layout/articles/article-list";
import { ArticleData } from "../layout/articles/ArticleData";
import { PlaceholderMessage, NewletterSignup } from "../components";

interface Props {
  data: {
    allMdx: {
      nodes: ArticleData[];
    };
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
}

const BlogIndex: FC<Props> = ({ data }) => {
  if (!isAuthenticated()) {
    login();
    return <PlaceholderMessage>Authenticating...</PlaceholderMessage>;
  }

  const posts = data.allMdx.nodes;

  return (
    <Layout>
      <MetaData />

      <NewletterSignup />

      <ArticleList posts={posts} />
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        wordCount {
          words
        }
        frontmatter {
          date(formatString: "YYYY-MM-DD")
          title
          description
          featuredImage
        }
      }
    }
  }
`;
