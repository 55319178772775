import React, { FC } from "react";
import classNames from "classnames/bind";
import { Link } from "gatsby";
import { ArticleData } from "./ArticleData";
import { Image } from "../../components";

import styles from "./ArticleList.module.scss";
const cx = classNames.bind(styles);

interface ArticleListProps {
  posts: ArticleData[];
}

export const ArticleList: FC<ArticleListProps> = ({ posts }) => (
  <div className={cx("ArticleList")}>
    {posts.map(post => {
      return (
        <Link
          to={post.fields.slug}
          key={post.fields.slug}
          className={cx("ArticleListItem")}
        >
          <div className={cx("HoverEffectContainer")}>
            <div className={cx("ArticleImage")}>
              <div className={cx("ArticleImageTile")}>
                <Image assetSrc={post.frontmatter.featuredImage} />
              </div>
            </div>
            <div className={cx("ArticleMetadata")}>
              <div className={cx("ArticleDate")}>{post.frontmatter.date}</div>
              <h1>{post.frontmatter.title}</h1>
              <p>
                {post.frontmatter.description === null
                  ? post.excerpt
                  : post.frontmatter.description}
              </p>
            </div>
          </div>
        </Link>
      );
    })}
  </div>
);
